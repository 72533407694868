import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { SlimHero } from "../Slices/SlimHero"

export function BlogTemplate({ data: { prismicBlog } }) {
  console.log(prismicBlog)
  return (
    <Layout>
      <SlimHero primary={{ title: prismicBlog.title }} />
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query Blog($slug: String) {
    prismicBlog(uid: { eq: $slug }) {
      data {
        title {
          html
          raw
        }
        copy {
          html
          raw
        }
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
