import React from "react"
import { graphql } from "gatsby"
import { SliceBuilder } from "../helpers/SliceBuilder"
import Layout from "../components/layout"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import Page from "../templates/page"
import BlogTemplate from "../templates/blog"

let ErrorPage = ({
  data: {
    prismicPage: { data },
  },
}) => {
  let meta = {
    title: data.meta_title.text,
    description: data.meta_copy.text,
  }
  return (
    <Layout meta={meta}>
      <SliceBuilder body={data.body} />
    </Layout>
  )
}

export default withUnpublishedPreview(ErrorPage, {
  templateMap: {
    page: Page,
    blog: BlogTemplate,
  },
})
export const query = graphql`
  query ErrorPage {
    prismicPage(uid: { eq: "404" }) {
      data {
        meta_copy {
          text
        }
        meta_title {
          text
        }
        body {
          ... on PrismicPageBodySlimHero {
            id
            slice_type
            primary {
              decoration_colour
              background
              title {
                raw
              }
              copy {
                raw
              }
            }
          }
        }
      }
    }
  }
`
